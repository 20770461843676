.customized-tooltip-content{
    background-color: rgb(24, 24, 24);
    padding:7px;
    text-align: center;
    /* width: 100%; */
    margin-right: 50px;
}

.cost_graph_holder{
    display: flex;
    flex-direction: column;
    position: relative;
    left: 10px;
}

.cost_graph_title{
    text-align: center;
    /* text-indent: 20px; */
    color: white;
    font-size: 26px;
    /* padding-bottom: 20px; */
}

.cost_graph_desc{
    text-align: center;
    /* text-indent: 20px; */
    color: rgb(124, 124, 124);
    font-size: 14px;
    padding-bottom: 0px;
    position: relative;
    top:-25px
}

.cost_breakdown_holder{
    /* width: 90%; */
    left: 5%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.total{
    font-size: 22px;
    color: white;
}

.data-list{
    text-align: center;
}

.cost_framer{
    display: flex;
    flex-direction: column;
}