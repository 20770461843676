.cost_analy_holder{
    display: flex;
    flex-direction: column;
    /* width: 200px; */
    /* min-width: 200px; */
    text-align: center;
}

.cost_analy_title{
    text-align: center;
    color: white;
    font-size: 26px;
}

.cost_analy_text{
    color: white;
    font-weight: lighter;
    font-size: 16px;
    text-align: left;
    /* text-indent: 50px; */
    /* width: 300px; */
    display: flex;
}

.cost_analy_high1{
    color:var(--ax-green);
    font-weight: bolder;
    font-size: 20px;
    padding-left: 20px;
}