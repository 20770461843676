.dash_title{
    font-size: 25px;
    color: white;
    text-align: center;
    width: 100%;
    position: relative;
    /* padding-top: 120px; */
}

.back_button{
    position: absolute;
    top: 45px;
    /* left: 5px; */
    left: 20%;
    height: 40px;
    width: 60%;
    border-radius: 5px;
    background-color: var(--ax-purple);
    cursor: pointer;
    z-index: 2;
    transition: .7s;
}
.back_button:hover{
    transition: .3s;
    background-color: var(--ax-pink);
}

.back_button_subtext{
    position: relative;
    top: -5px;
    color: white;
    font-weight: bolder;
}

.kpi_shelf{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cost_breakdown_holder{
    width: 90%;
    left: 5%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dash_title_sub{
    color:var(--ax-green);
}