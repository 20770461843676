.app_header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    background-color: var(--ax-black);
    top: 0;
    left: 0;
    padding:5px;
    /* background-color: #a5a5a5; */
    /* padding: 5px; */
    z-index: 99;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--ax-purple);
}


.top_img_ph{
    height: 30px;
    padding-left: 15px;
    margin: 18px;
}

.top_img_em{
    height: 30px;
    padding-left: 0px;
    margin: 18px;
}

.top_preview_button{
    background-color: var(--ax-green);
    height: 30px;
    position: relative;
    top: 5px;
    margin-top: 15px;
    left: 27%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    margin-bottom: 25px;
}

.top_preview_text{
    font-weight: bolder;
    position: relative;
    top: -10px;
    font-size: 16px;
}