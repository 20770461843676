.subtext{
    text-align: center;
    color: var(--ax-green);
    font-size: 16px;
    text-align: center;
    width: 90%;
    left: 5%;
    position: relative;
    margin-bottom: 50px;
}

.trust_mobile_graphic{
    /* height: 70px;
    width: 230px; */
    height: 70px;
    width: 60%;
    background-color: var(--ax-darkgrey);
    position: relative;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    margin-top: 20px;
    left: 20%;
    animation-name: line_glow;
    animation-duration: 6s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.trust_mobile_graphic_txt{
    color: white;
    font-size: 18px;
    font-weight: bolder;
    position: relative;
    top: 7px;
}

@keyframes line_glow {

    0%{
        border-bottom-color: var(--ax-purple);
        border-top-color:var(--ax-purple);
        border-right-color:var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

    20%{
        border-bottom-color: var(--ax-purple);
        border-top-color:var(--ax-purple);
        border-right-color:white;
        border-left-color: white;
    }

    40%{
        border-bottom-color: white;
        border-top-color:white;
        border-right-color:var(--ax-purple);
        border-left-color: var(--ax-purple);
    }
    
    60%{
        border-bottom-color: var(--ax-purple);
        border-top-color:var(--ax-purple);
        border-right-color:white;
        border-left-color: white;
    }

    80%{
        border-bottom-color: white;
        border-top-color:white;
        border-right-color:var(--ax-purple);
        border-left-color: var(--ax-purple);
    }



    100%{
        border-bottom-color: var(--ax-purple);
        border-top-color:var(--ax-purple);
        border-right-color:var(--ax-purple);
        border-left-color: var(--ax-purple);
    }

}