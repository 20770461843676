/* .supply_text{
    color: var(--ax-green);
    width:600px;
    text-align: left;
    font-size: 18px;
}

.supply_title{
    color: white;
    text-align: left;
    font-weight: 900;
    font-size: 32px;
}

.holder{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    left: 25%;
    position: relative;
} */

.growth_holder{
    height: 200px;
    width: 200px;
    margin-right: 40px;
    position: relative;
    display: flex;
    left: 22%;
    justify-content: center;
}

.growth_class{
    position: relative;
    top: 30px;
    height: 150px;
    width: 200px;
}

