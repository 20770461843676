.slogan{
    color: var(--ax-green);
    font-weight: 100;
    font-size: 24px;
    /* padding-top: 25px; */
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
}

.logo_img{
    height: 130px;
    padding-top: 135px;
}

.slogan_sub{
    color: white;
    font-weight: 100;
    font-size: 16px;
    /* padding-top: 25px; */
    padding-left: 30px;
    padding-right: 30px;
}