.title{
    /* font-size: 32px; */
    color: white;
    text-align: center;
    /* width: 100%; */
    position: relative;
    font-size: 26px;
    text-align: center;
    width: 80%;
    left: 10%;
}

.consult_holder{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    /* width: 600px; */
    /* left: 25%; */
    /* min-width: 50%; */
    position: relative;
    margin-bottom: 50px;
}

.consult_item{
    height: 10px;
    /* width: 35%; */
    border-radius: 5px;
    margin:20px;
    text-align: center;
    font-size: 20px;
}

.consult_text{
    text-align: center;
    font-weight: bolder;
    color: var(--ax-green);
    /* font-size:28px; */
    white-space: nowrap;
    transition: 1s;
}


.consult_grand_holder{
    display: flex;
    justify-content: center;
    flex-direction: column;
}