/* .supply_text{
    color: var(--ax-green);
    width:600px;
    text-align: left;
    font-size: 18px;
}

.supply_title{
    color: white;
    text-align: left;
    font-weight: 900;
    font-size: 32px;
}

.holder{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    left: 25%;
    position: relative;
} */

.gear_holder{
    height: 200px;
    width: 200px;
    margin-right: 40px;
    position: relative;
    left: 22%;
    display: flex;
    justify-content: center;
}

.gear_class{
    animation-name: heartbeat;
    animation-duration: 3s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    height: 200px;
    width: 200px;
}


@keyframes heartbeat
{
  0%
  {
    transform: scale( .85 );
  }
  10%
  {
    transform: scale( 1 );
  }
  20%
  {
    transform: scale( .85 );
  }
  30%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .85 );
  }
  100%
  {
    transform: scale( .85 );
  }
}