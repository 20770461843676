.kpi_holder{
    background-color: #0E0E0E;
}

.kpi_subtext{
    text-align: center;
    color: var(--ax-green);
    font-size: 20px;
    text-align: center;
    /* width: 50%;
    left: 25%; */
    position: relative;
    margin-bottom: 30px;
}
